@import "_bootstrap";

html {
  height: 100%;
}
body {
  min-height: 100%;
  margin:0;
  overflow-x: hidden;
}

img{
    height: auto;
    width: auto;
    max-width: 100%;
}
.nav.nav-tabs{
    margin-bottom:-1px;
    .active{
        a{
            background-color:#d9edf7;
            border-color: #bce8f1;
            border-bottom::1px solid #d9edf7;
            color: #31708f;

        }
    }
}
.container{
    padding: 20px 15px;
}

/* gestion */
.panel-heading{
	overflow: hidden;
	display: flex;
	justify-content: space-between;
	align-items: center;
	
	p{
		margin-bottom: 0;
	}
}

.image-preview{
	margin-bottom: 15px;
}

.gestion-promotions .thumbnail-promo{
    max-width: 100px;
}

/* promotions */
.promotion-form{
    
}

/* borne */
#borne-page{
    position: relative;
    .screensaver-overlay{
        position:absolute;
        z-index:100000000;
        width:100vw;
        height:100vh;        
    }
    .navbar{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
    }
    
    .page-wrapper{
        padding: 152px 60px 0;
    }
    
    .big-button-row{
        padding: 12px 0;
        
        .big-button-wrap{
            padding: 0 12.5px;
        
            .big-button{
                display: flex;
                flex-direction: column;
                background-color: #ccc;
                width: 100%;
                height: 394px;
                border-radius: 8px;
                text-align: center;
                padding: 13px 17px;
                box-shadow: 0px 8px 6px 0px rgba(0,0,0,0.26);
                transition: all 0.3s;
                
                h1{
                    text-align: left;
                    font-family: 'bebas_neuebook';
                    font-size: 43px;
                    color: #ffffff;
                    text-transform: uppercase;
                    letter-spacing: 0.01em;
                    margin: 0 0 26px;
                    text-shadow: 1px 4px 3px rgba(0, 0, 0, 0);
                    transition: all 0.3s;
                }
                
                .icon-wrap{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex: 1 1 auto;
                    
                    svg{
                        transition: all 0.3s;
                        filter: drop-shadow(0px 8px 6px 0px rgba(0,0,0,0));
                    }
                }
                
                
                &:hover,
                &:focus{
                    text-decoration: none;
                }
            }
        }
    }
    
    
    /* pages general */
    .borne-page-header{
        max-width: 830px;
        margin: 0 auto;
        display: flex;
        align-items: flex-end;
        margin-bottom: 10px;
        position: relative;
        figure{
            padding: 0;
        }
        
        .small-menu{
            padding-right: 0;
        }
        
        .small-button-wrap{
            padding: 0 0 0 10px;
            
            .small-button{
                display: flex;
                background-color: #ccc;
                width: 100%;
                height: 90px;
                border-radius: 8px;
                align-items: center;
                justify-content: center;
                text-align: center;
                transition: all 0.3s;
                
                .icon-wrap{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                
                    height: 69px;
                    
                    svg{
                        max-width: 100%;
                        max-height: 100%;
                        width: auto;
                        height: auto;
                        transition: all 0.3s;
                    }
                }
                
                &:hover,
                &:focus{
                    text-decoration: none;
                }
            }
        }
    }
    
    /* promotions */
    .promotions-list{
        width: 100%;
        
        .grid{
            margin: 0 auto;
            float: none;
        }
        .page{
            display:none;
            &.active{
                display:block;
            }
        }
        .pagination{
            width:100%;
            text-align: center;
             li{display:inline-block;}
        }
        .pagination a{
            font-size: 2em;
             padding: 20px 30px;
        }
        .promotion{
            position: relative;
            margin-bottom: 15px;
            border-radius: 8px;

            .in-grid{
                cursor: pointer;
                height:230px;
                background-size:cover;           
                background-position: center center;  
                overflow: hidden;
                border-radius: 8px;
                box-shadow: 0px 8px 6px 0px rgba(0,0,0,0.26);
                background-color:#fff;
                position:relative;
                z-index:2;   
                background-repeat: no-repeat;                          
            }
            .in-grid.type-aucun{             
                    background-size: 90%;        
            }
            
            h2{                  
                    color: #ffffff;                   
                    text-align: center;
                    margin: -10px auto 0px auto;;
                    padding: 25px 10px 0px 0px;
                    font-size:18px;
                    z-index: 1;
                    text-shadow: 1px 1px 3px rgba(#000, 0.5);
                    border-radius: 8px;
                    height:60px;
                    text-transform: uppercase;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
        }       
    }

     
        .popup{
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            background-color: rgba(#000, 0.8);
            padding: 20px;
           
            opacity: 0;
            z-index: -10;
            transition: opacity 0.3s;
            
            &.open{
                z-index: 10;
                opacity: 1;
            }
            
            .container{
                background-color: #ffffff;
                position: relative;
                display: flex;
                border-radius: 8px;
                max-height:97vh;
                .contenu{
                    display: flex;
                    width: 100%;
                    
                    .popup-content{
                        display: flex;
                        flex-direction: column;
                        h1{
                            color:#fff;
                            text-transform: uppercase;
                             text-shadow: 1px 1px 3px rgba(#000, 0.5);
                        }
                        p{
                            color:#000;
                            background-color:#fff;
                            padding:20px;
                            border-radius:8px;
                        }
                        figure{
                            overflow: hidden;
                        }
                        
                        .fluid-iframe{
                            position: relative;
                           // padding-bottom: 56.25%; /* proportion value to aspect ratio 16:9 (9 / 16 = 0.5625 or 56.25%) */
                           // flex: 1 0 auto;
                            overflow: hidden;
                        
                            iframe{
                                //position: absolute;
                                //top: 0; 
                                left: 0;
                                width: 100%;
                                height: 67vh;
                                border-radius: 8px;
                            }
                        }
                    }
                }
                
                .close-popup{
                    position: absolute;
                    right: -15px;
                    top: -15px;
                    color: #ec3d47;
                    font-size: 20px;
                    font-weight: 700;
                    cursor: pointer;
                    z-index: 20;
                    width: 40px;
                    height: 40px;
                    line-height: 32px;
                    border: 3px solid #ec3d47;
                    border-radius: 50%;
                    text-align: center;
                    background-color: #fff;
                }
            }
        }
    
    /* formulaire */
    .page-formulaire{
        max-width: 830px;
        margin: 0 auto;
        background-color: #ccc;
        border-radius: 8px;
        padding: 30px 20px 35px;
        box-shadow: 0px 8px 6px 0px rgba(0,0,0,0.26);
        
        .title-wrap{
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            
            svg{
                width: 49px;
                height: auto;
            }
            
            h1{
                text-transform: uppercase;
                font-family: 'bebas_neuebook';
                color: #ffffff;
                font-size: 40px;
                margin: 0 0 0 15px;
                display: inline-block;
            }

        }
        .texte-wrap{
             display: flex;
               align-items: center;
              margin-bottom: 15px;
             p{
                clear:both;                         
                color: #ffffff;
                font-size: 20px;
                margin: 0 64px 0 64px;
                display: block;
            }
        }
        form{
            padding: 0 65px;
            
            .form-group{
                margin-left: 0;
                margin-right: 0;
                
                input{
                    border: none;
                    background-color: #ffffff;
                    border-radius: 8px;
                    font-family: 'bebas_neuebook';
                    font-size: 41px;
                    color: #6a6a6a;
                    height: 65px;
                }
            }
            .help-block {
                background: #fff;
                padding: 5px 15px;
                border-radius: 8px;
            }
            button{
                border: none;
                background-color: rgba(#000, 0.3);
                border-radius: 8px;
                text-transform: uppercase;
                font-family: 'bebas_neuebook';
                font-size: 41px;
                color: #ffffff;
                height: 65px;
                width: 285px;
                margin: 0 auto;
                display: block;
                transition: all 0.3s;
                
            }
            
            .form-message{
                color: #ffffff;
                font-size: 16px;
                text-align: center;
                margin-top: 10px;
            }
        }
    }
    .jconfirm-title{
        .error{
            color:red;
        }
        .warning{
            color:orange;
        }
        .success{
            color:green;
        }
    }
    /* circulaire */  
    .page-circulaire{
        .circulaire-wrapper{
            overflow:hidden;
        }
        .col-md-12{
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 76%;
        }
        .circulaire{
            position: absolute;
            top: -32px;
            left: 0;
            width: 100%;
            height: 104%;
            overflow: hidden;
        }
    }
}